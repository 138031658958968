<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header> </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="3">
            <site-group-filter store="premium" />
          </v-col>
          <v-col cols="3">
            <site-filter store="premium" />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="audienceType"
              :items="audienceTypes"
              label="Type d'audience"
              outlined
              color="primary"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <apply-filters
              :disabled="isLoading"
              outlined
              color="primary"
              title="Appliquer"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";

import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

export default {
  name: "Filters",
  components: {
    SiteFilter,
    SiteGroupFilter,
    ApplyFilters,
  },
  data() {
    return {
      audienceTypes: [
        { value: "ga", text: "GA" },
        { value: "wysistat", text: "Wysistat" },
      ],
    };
  },
  async created() {
    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: "general/updateAudienceType",
      param: URL_PARAM_NAMES["AudienceType"],
      value: this.audienceType,
      // is_multiple: false,
      // is_integer: false,
      // is_boolean: false,
      // dependsOn: undefined,
    });
  },
  computed: {
    isLoading() {
      return this.$store.getters["common/getLoading"];
    },
    audienceType: {
      get() {
        return this.$store.getters["general/getAudienceType"];
      },
      set(val) {
        this.$store.dispatch("general/updateAudienceType", val);
      },
    },
  },
  watch: {
    audienceType(newValue) {
      addQueryStringParam({
        router: this.$router,
        route: this.$route,
        param: URL_PARAM_NAMES["AudienceType"],
        value: newValue,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
      });
    },
  },
};
</script>
